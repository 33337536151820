import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  title: string
  subtitle: string
  imageTitle: string
  image: string
  colorPalette: ColorPalette
}

/** const */
const HeroTopSection: React.FC<Props> = (props) => {
  return (
    <div
      className="container-fluid text-center hero"
      style={{
        color: "#FFF",
        background: `linear-gradient(${props.colorPalette.heroTop} 30%, ${props.colorPalette.heroBottom} 70%)`,
      }}
    >
      <div className="heroContainer">
        <h3 className="heroTitle" style={{color: "#FFFFFF"}}>{props.title}</h3>
        <h3 className="heroSubTitle" style={{color: "#FFFFFF"}}>{props.subtitle}</h3>
      </div>

      <div className="container" data-aos="fade">
        <div className="col-sm-12">
          <img
            loading="eager"
            className="mw-100 h-100"
            width="800"
            height="400"
            src={props.image}
            alt={props.imageTitle}
          />
        </div>
      </div>
    </div>
  )
}

export default HeroTopSection
